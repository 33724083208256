import React from 'react';
import { graphql } from 'gatsby';
import Header from 'components/Header';
import Footer from 'components/Footer';
import Single from 'singles/Cases/Single';

const SingleCase = ({
  data: {
    ateliware: { project, headerMenu, footerMenu }
  },
  pageContext
}) => {
  return (
    <React.Fragment>
      <Header menu={headerMenu} pageContext={pageContext} />
      <Single project={project} pageContext={pageContext} />
      <Footer
        footerMenu={footerMenu}
        pageContext={pageContext}
        privatePolicyTitle={project.contactForm.privacyPolicyTitle}
      />
    </React.Fragment>
  );
};

export const projectQuery = graphql`
  query ProjectPageQuery($idProject: ID!, $locale: [GCMS_Locale!]!) {
    ateliware {
      project(where: { id: $idProject }, locales: $locale) {
        id
        slug
        title
        locale
        seo {
          metaTitle
          metaDescription
          ogTitle
          ogDescription
          ogType
          ogImage {
            url
          }
        }
        featuredImage(locales: pt) {
          handle
          width
          height
          title
          alt
          url
        }
        coverTitle
        introDescription
        segment
        outcome
        tools
        projectTime
        testimonial {
          description
          role
          author
        }
        introImage(locales: pt) {
          handle
          width
          height
          title
          url
          alt
        }
        whyTitle
        why
        whyDescription
        whyPhoto(locales: pt) {
          handle
          width
          height
          title
          url
          alt
        }
        howTitle
        how
        howDescriptionLeft
        howDescriptionRight
        howPhoto(locales: pt) {
          handle
          width
          height
          title
          url
          alt
        }
        whatTitle
        what
        whatDescription
        projectLabel
        projectUrl
        contactForm {
          title
          content
          nameField
          surnameField
          companyField
          emailField
          phoneField
          serviceOptionDefault
          serviceOption1
          serviceOption2
          serviceOption3
          serviceOption4
          aboutYouField
          budgetDefault
          budgetOption1
          budgetOption2
          budgetOption3
          sendButtonText
          formErrorMessage
          privacyPolicyTitle
          privacyPolicyText
          thankYouTitle
          thankYouContent
          backToSiteText
          toBlogText
        }
      }
      headerMenu: menu(where: { title: "header" }, locales: $locale) {
        locale
        callToAction
        menuItems {
          title
          slug
          locale
          goTo
          externalLink
          subMenuItems {
            title
            slug
            locale
            goTo
            externalLink
          }
        }
      }
      footerMenu: menu(where: { title: "footer" }, locales: $locale) {
        locale
        callToAction
        menuItems {
          title
          slug
          locale
          goTo
          externalLink
        }
      }
    }
  }
`;

export default SingleCase;

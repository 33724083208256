import React from 'react';
import { StyledWrapper } from 'assets/global/styled';
import MarkdownPreview from '@uiw/react-markdown-preview';
import {
  StyledIntro,
  StyledIntroContainer,
  StyledIntroTitle,
  StyledIntroDescription
} from './styled';

const Intro = ({ project }) => {
  const {
    coverTitle,
    featuredImage,
    segment,
    outcome,
    tools,
    projectTime,
    introDescription
  } = project;

  return (
    <StyledIntro bg={featuredImage?.handle}>
      <StyledWrapper>
        <StyledIntroContainer>
          {coverTitle && (
            <StyledIntroTitle>{coverTitle}</StyledIntroTitle>
          )}
          <StyledIntroDescription>
            <MarkdownPreview source={introDescription} />
          </StyledIntroDescription>
        </StyledIntroContainer>
      </StyledWrapper>
    </StyledIntro>
  );
};

export default Intro;

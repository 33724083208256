import React from 'react';
import MarkdownPreview from '@uiw/react-markdown-preview';
import LogoClutch from 'assets/images/logos/AR_Developers.webp';
import LogoManifest from 'assets/images/logos/badge-manifest.webp';
import MicrosoftLogo from 'assets/images/logos/Silver-ERP-DataAnalytics.webp';
import {
  StyledContactUsTitle,
  StyledContactUsSubTitle,
  StyledContactUsBadges
} from '../styled';

const LpDescription = ({ page }) => {
  return (
    <React.Fragment>
      <StyledContactUsSubTitle>
        <MarkdownPreview
          source={page.contentTitle}
          linkTarget={'_blank'}
        />
      </StyledContactUsSubTitle>
      <StyledContactUsTitle>{page.title}</StyledContactUsTitle>
      <MarkdownPreview
        source={page.contentSubtitle}
        linkTarget={'_blank'}
      />
      <StyledContactUsBadges>
        <img
          src={LogoClutch}
          width="101px"
          height="110px"
          alt="Ateliware"
          align="center"
        />
        <img
          src={LogoManifest}
          width="80px"
          height="100px"
          alt="Ateliware"
          align="center"
        />
        <img
          src={MicrosoftLogo}
          width="205px"
          height="51px"
          alt="Ateliware"
          align="center"
        />
      </StyledContactUsBadges>
    </React.Fragment>
  );
};

export default LpDescription;

import React from 'react';
import MarkdownPreview from '@uiw/react-markdown-preview';
import { StyledWrapper } from 'assets/global/styled';
import { StyledContentMarkdown } from '../../../../components/Content/styled';
import {
  StyledContent,
  StyledTestimonial,
  StyledTestimonialContent,
  StyledImageFull,
  StyledStep,
  StyledStepContent,
  StyledStepContentFull,
  StyledStepTitle,
  StyledStepSubtitle
} from 'singles/Cases/styled';
import GraphImg from 'graphcms-image';

const Content = ({ project }) => {
  const {
    title,
    testimonial,
    introImage,
    whyTitle,
    why,
    whyDescription,
    whyPhoto,
    howTitle,
    how,
    howDescriptionLeft,
    howDescriptionRight,
    howPhoto,
    whatTitle,
    what,
    whatDescription,
    projectLabel,
    projectUrl
  } = project;

  return (
    <React.Fragment>
      <StyledContent>
        <StyledWrapper>
          <StyledTestimonial>
            {testimonial?.author && testimonial?.description && (
              <StyledTestimonialContent>
                <blockquote>
                  <p>{testimonial?.description}</p>
                </blockquote>
                {testimonial?.author && testimonial?.role && (
                  <h6>
                    <em>
                      {testimonial?.author} | {testimonial?.role}
                    </em>
                  </h6>
                )}
              </StyledTestimonialContent>
            )}
            {introImage?.url && (
              <StyledImageFull>
                <GraphImg
                  baseURI="https://media.graphassets.com"
                  image={introImage}
                  maxWidth={1200}
                  alt={introImage?.alt || title}
                  fit="clip"
                />
              </StyledImageFull>
            )}
          </StyledTestimonial>
        </StyledWrapper>
      </StyledContent>

      <StyledContent>
        <StyledWrapper>
          <StyledStep>
            <StyledStepContent>
              <StyledStepSubtitle>
                1 <span>{whyTitle}</span>
              </StyledStepSubtitle>
              <StyledStepTitle>{why}</StyledStepTitle>
              {whyPhoto?.url && (
                <GraphImg
                  baseURI="https://media.graphassets.com"
                  image={whyPhoto}
                  maxWidth={600}
                  alt={whyPhoto?.alt || why}
                  fit="clip"
                />
              )}
            </StyledStepContent>
            <StyledStepContent>
              <MarkdownPreview source={whyDescription} />
            </StyledStepContent>
          </StyledStep>
        </StyledWrapper>
      </StyledContent>

      <StyledContent>
        <StyledWrapper>
          <StyledStep>
            <StyledStepContent>
              <StyledStepSubtitle>
                2 <span>{howTitle}</span>
              </StyledStepSubtitle>
              <StyledStepTitle>{how}</StyledStepTitle>
            </StyledStepContent>
            {howDescriptionLeft && (
              <StyledStepContent>
                <StyledContentMarkdown>
                  <MarkdownPreview source={howDescriptionLeft} />
                </StyledContentMarkdown>
              </StyledStepContent>
            )}
            {howDescriptionRight && (
              <StyledStepContent>
                <StyledContentMarkdown>
                  <MarkdownPreview source={howDescriptionRight} />
                </StyledContentMarkdown>
              </StyledStepContent>
            )}
            {howPhoto?.url && (
              <StyledStepContent>
                {/* Não pegou o howPhoto, nao entendi o porque //TODO: verificar essa imagem */}
                {/* <GraphImg image={howPhoto} maxWidth={600} alt={howPhoto?.alt || how} fit="clip" /> */}
                <img src={howPhoto?.url} alt={howPhoto?.alt || how} />
              </StyledStepContent>
            )}
          </StyledStep>
        </StyledWrapper>
      </StyledContent>

      <StyledContent>
        <StyledWrapper>
          <StyledStep>
            <StyledStepContentFull>
              <StyledStepSubtitle>
                3 <span>{whatTitle}</span>
              </StyledStepSubtitle>
              <StyledStepTitle>{what}</StyledStepTitle>
              <StyledContentMarkdown>
                <MarkdownPreview source={whatDescription} />
              </StyledContentMarkdown>
            </StyledStepContentFull>

            {projectUrl && (
              <StyledStepContentFull>
                <a
                  href={projectUrl}
                  title={projectLabel}
                  target="blank"
                  rel="noopener noreferrer"
                >
                  {projectLabel}
                </a>
              </StyledStepContentFull>
            )}
          </StyledStep>
        </StyledWrapper>
      </StyledContent>
    </React.Fragment>
  );
};

export default Content;

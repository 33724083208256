import styled from 'styled-components';
import colors from 'assets/global/colors';
import fonts from 'assets/global/fonts';

export const StyledContactUs = styled.section`
  display: block;
  width: 100%;
  ${(props) =>
    props.isLp
      ? 'height: fit-content'
      : props.dark
      ? null
      : 'height: 100vh'};
  padding: 80px 0;

  ${(props) =>
    props.isLp
      ? 'padding-bottom: 80px'
      : props.dark
      ? null
      : 'padding-bottom: 45em'};

  ${(props) =>
    props.dark
      ? `background-color: ${colors.gray900};`
      : props.bg_image === ''
      ? `background-color: colors.gray50;`
      : null};

  ${(props) =>
    props.bg_image !== ''
      ? `background-image: url(${props.bg_image});`
      : null};

  ${(props) =>
    props.bg_image !== '' ? `background-size: 110% auto;` : null};

  @media screen and (max-width: 980px) {
    height: auto;
    padding: 60px 0 30px 0;
    ${(props) =>
      props.bg_image !== '' ? `background-size: 110% 65%;` : null};
    ${(props) =>
      props.bg_image !== '' ? `background-repeat: no-repeat;` : null};
    ${(props) =>
      props.bg_image !== ''
        ? `background-position: left bottom;`
        : null};
  }

  @media screen and (max-width: 768px) {
    padding: 60px 0 30px 0;

    h2 {
      font-family: ${fonts.secondary};
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 20px;

      &:before {
        display: none;
      }
    }
  }
`;

export const StyledContactUsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  ${(props) => (props.dark ? 'flex-direction: row-reverse;' : null)}
  align-items: start;
  ${(props) => (props.dark ? 'gap: 150px;' : null)}
  ${(props) =>
    props.dark ? `flex-wrap: nowrap;` : `flex-wrap: wrap;`};
  height: 100%;

  @media screen and (max-width: 980px) {
    ${(props) =>
      props.dark ? 'flex-direction: column-reverse;' : null}
    ${(props) => (props.dark ? `flex-wrap: wrap;` : null)};
    align-content: center;
  }

  form {
    width: 48.5%;
    margin-top: 15px;
    text-align: ${(props) => (props.dark ? 'right' : 'left')};
    ${(props) => (props.dark ? 'padding: 0px;' : null)}

    div {
      p {
        ${(props) => (props.dark ? 'color: white;' : null)};
      }
    }

    @media screen and (max-width: 980px) {
      width: 100%;
    }
  }
`;

export const StyledContactUsContent = styled.div`
  position: relative;
  z-index: 5;
  display: block;
  width: 50%;

  img {
    ${(props) => (props.dark ? 'margin-right: 40px;' : null)};

    @media screen and (max-width: 980px) {
      ${(props) => (props.dark ? 'margin-left: 20px;' : null)};
      ${(props) => (props.dark ? 'margin-right: 0px;' : null)};
    }
  }

  p {
    font-size: 18px;
    font-weight: 300;
    color: ${(props) => (props.dark ? colors.white : colors.gray300)};
    line-height: 1.8;
    font-family: ${fonts.secondary};

    strong {
      font-weight: 700;
    }
  }

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;

    li {
      margin-top: 10px;
      font-size: 16px;
      color: ${(props) =>
        props.dark ? colors.white : colors.gray700};

      &:first-child {
        margin-top: 0;
      }
    }
  }

  a {
    position: relative;
    display: inline-block;
    margin-top: 20px;
    font-size: 16px;
    color: ${colors.red400};
    text-decoration: none;

    &:before,
    &:after {
      position: absolute;
      bottom: 0;
      width: 0;
      height: 1px;
      background-color: ${colors.red400};
      transition: all 0.3s linear;
      content: '';
    }

    &:before {
      left: 0;
    }

    &:after {
      right: 0;
    }

    &:hover {
      &:before,
      &:after {
        width: 100%;
      }
    }
  }
  }

  @media screen and (max-width: 980px) {
    width: 100%;
  }

  @media screen and (max-width: 520px) {
    p {
      font-size: 16px;
    }
  }
`;

export const StyledContactUsTitle = styled.h2`
  margin-top: 0;
  margin-bottom: 40px;
  font-family: ${fonts.primary};
  font-size: 60px;
  color: ${(props) => (props.dark ? colors.white : colors.gray300)};
  line-height: 1.1;

  @media screen and (max-width: 980px) {
    font-size: 60px;
  }

  @media screen and (max-width: 768px) {
    font-size: 42px;
  }

  @media screen and (max-width: 520px) {
    font-size: 28px;
  }
`;

export const StyledContactUsBadges = styled.div`
  padding-top: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 980px) {
    font-size: 60px;
  }

  @media screen and (max-width: 768px) {
    font-size: 42px;
  }

  @media screen and (max-width: 520px) {
    font-size: 28px;
  }
`;
export const StyledContactUsSubTitle = styled.h2`
  margin-bottom: 20px;
`;

export const StyledContactUsSubContent = styled.div`
  font-family: ${fonts.secondary};
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  color: white;

  @media screen and (max-width: 980px) {
    font-family: ${fonts.secondary};
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
`;

export const StyledLineSeparator = styled.div`
  display: block;
  border-bottom: solid 1px white;
  width: 400px;
  margin-top: 50px;

  @media screen and (max-width: 980px) {
    width: 300px;
  }
`;

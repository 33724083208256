import React from 'react';
import Helmet from 'react-helmet';
import SEO from 'components/SEO';
import Intro from 'singles/Cases/Single/Intro';
import Content from 'singles/Cases/Single/Content';
import ContactUs from 'components/ContactUs';

const Single = ({ project, pageContext }) => {
  const { seo, contactForm, locale, title } = project;
  const { baseURL } = pageContext;

  return (
    <React.Fragment>
      {(() => {
        switch (title) {
          case 'Bcredi':
            return (
              <SEO
                meta_title={
                  locale == 'pt'
                    ? seo?.metaTitle
                    : "we co-created Bcredi's operating system"
                }
                meta_description={
                  locale == 'pt'
                    ? seo?.metaDescription
                    : 'Find out how we helped Bcredi build a robust system, using Elixir and storing Big Data in BigQuery, in just 6 months.'
                }
                og_title={
                  locale == 'pt'
                    ? seo?.ogTitle
                    : "we co-created Bcredi's operating system"
                }
                og_description={
                  locale == 'pt'
                    ? seo?.ogDescription
                    : 'Find out how we helped Bcredi build a robust system, using Elixir and storing Big Data in BigQuery, in just 6 months.'
                }
                og_type={seo?.ogType}
                og_image={seo?.ogImage?.url}
                lang={locale}
              />
            );
          case 'Agrofel':
            return (
              <SEO
                meta_title={
                  locale == 'pt'
                    ? seo?.metaTitle
                    : 'We implement Microsoft Dynamics365 for Agrofel'
                }
                meta_description={
                  locale == 'pt'
                    ? seo?.metaDescription
                    : "Improve your processes. See how we implemented and integrated, in just 11 months, Microsot's Dynamics365 in several sectors of Agrofel."
                }
                og_title={
                  locale == 'pt'
                    ? seo?.ogTitle
                    : 'We implement Microsoft Dynamics365 for Agrofel'
                }
                og_description={
                  locale == 'pt'
                    ? seo?.ogDescription
                    : "Improve your processes. See how we implemented and integrated, in just 11 months, Microsot's Dynamics365 in several sectors of Agrofel."
                }
                og_type={seo?.ogType}
                og_image={seo?.ogImage?.url}
                lang={locale}
              />
            );
          case 'minestore':
            return (
              <SEO
                meta_title={
                  locale == 'pt'
                    ? seo?.metaTitle
                    : "We built minestore, GetNet's e-commerce SaaS"
                }
                meta_description={
                  locale == 'pt'
                    ? seo?.metaDescription
                    : "Find out about ateliware's participation in the construction of minestore, a SaaS e-commerce platform, now a Getnet product."
                }
                og_title={
                  locale == 'pt'
                    ? seo?.ogTitle
                    : "We built minestore, GetNet's e-commerce SaaS"
                }
                og_description={
                  locale == 'pt'
                    ? seo?.ogDescription
                    : "Find out about ateliware's participation in the construction of minestore, a SaaS e-commerce platform, now a Getnet product."
                }
                og_type={seo?.ogType}
                og_image={seo?.ogImage?.url}
                lang={locale}
              />
            );
          case 'Pipefy':
            return (
              <SEO
                meta_title={
                  locale == 'pt'
                    ? seo?.metaTitle
                    : 'We co-created Pipefy, a process management platform'
                }
                meta_description={
                  locale == 'pt'
                    ? seo?.metaDescription
                    : 'In 3 months we created an MVP for Pipefy, which allowed it to be accelerated by 500 startups in Silicon Valley. We transform the product into a business, facilitating its insertion in the investment market.'
                }
                og_title={
                  locale == 'pt'
                    ? seo?.ogTitle
                    : 'We co-created Pipefy, a process management platform'
                }
                og_description={
                  locale == 'pt'
                    ? seo?.ogDescription
                    : 'In 3 months we created an MVP for Pipefy, which allowed it to be accelerated by 500 startups in Silicon Valley. We transform the product into a business, facilitating its insertion in the investment market.'
                }
                og_type={seo?.ogType}
                og_image={seo?.ogImage?.url}
                lang={locale}
              />
            );
          case 'Votorantim S.A':
            return (
              <SEO
                meta_title={
                  locale == 'pt'
                    ? seo?.metaTitle
                    : 'Alert generation solution for Votorantim'
                }
                meta_description={
                  locale == 'pt'
                    ? seo?.metaDescription
                    : 'We developed a fraud alert system for Grupo Votorantim SA, which uses telemetry and actionable data with machine learning. Know more.'
                }
                og_title={
                  locale == 'pt'
                    ? seo?.ogTitle
                    : 'Alert generation solution for Votorantim'
                }
                og_description={
                  locale == 'pt'
                    ? seo?.ogDescription
                    : 'We developed a fraud alert system for Grupo Votorantim SA, which uses telemetry and actionable data with machine learning. Know more.'
                }
                og_type={seo?.ogType}
                og_image={seo?.ogImage?.url}
                lang={locale}
              />
            );
          case 'MatrixCargo':
            return (
              <SEO
                meta_title={
                  locale == 'pt'
                    ? seo?.metaTitle
                    : "We co-created MatrixCargo's operating system"
                }
                meta_description={
                  locale == 'pt'
                    ? seo?.metaDescription
                    : 'Find out how we co-created the artificial intelligence logistics control system for MatrixCargo'
                }
                og_title={
                  locale == 'pt'
                    ? seo?.ogTitle
                    : "We co-created MatrixCargo's operating system"
                }
                og_description={
                  locale == 'pt'
                    ? seo?.ogDescription
                    : 'Find out how we co-created the artificial intelligence logistics control system for MatrixCargo'
                }
                og_type={seo?.ogType}
                og_image={seo?.ogImage?.url}
                lang={locale}
              />
            );
          case 'FAEL':
            return (
              <SEO
                meta_title={
                  locale == 'pt'
                    ? seo?.metaTitle
                    : 'We co-created the new FAEL Student Portal and App'
                }
                meta_description={
                  locale == 'pt'
                    ? seo?.metaDescription
                    : 'Find out how we co-created the new FAEL Student Portal and App'
                }
                og_title={
                  locale == 'pt'
                    ? seo?.ogTitle
                    : 'We co-created the new FAEL Student Portal and App'
                }
                og_description={
                  locale == 'pt'
                    ? seo?.ogDescription
                    : 'Find out how we co-created the new FAEL Student Portal and App'
                }
                og_type={seo?.ogType}
                og_image={seo?.ogImage?.url}
                lang={locale}
              />
            );
          default:
            return (
              <SEO
                meta_title={seo?.metaTitle}
                meta_description={seo?.metaDescription}
                og_title={seo?.ogTitle}
                og_description={seo?.ogDescription}
                og_type={seo?.ogType}
                og_image={seo?.ogImage?.url}
                lang={locale}
              />
            );
        }
      })()}

      <Intro project={project} />
      <Content project={project} />
      <ContactUs
        contactForm={contactForm}
        baseURL={baseURL}
        lang={locale}
      />
      <Helmet>
        <script type="application/ld+json">
          {`
            {
              "@context":"https://schema.org",
              "@type":"Organization",
              "name":"ateliware",
              "legalName":"ateliware",
              "url":"http://ateliware.com/cases",
              "logo":"https://media.graphassets.com/gJsd71c5SGh1RyV8tIrV",
              "address":{
                "@type":"PostalAddress",
                "streetAddress":"Rua Pasteur, 463",
                "addressLocality":"Curitiba",
                "addressRegion":"Paraná",
                "postalCode":"80250-080",
                "addressCountry":"Brasil"
              },
              "contactPoint":{
                "@type":"ContactPoint",
                "contactType":"Contato",
                "telephone":"[+55 41 3010-2275]",
                "email":"hello@ateliware.com"
              },
              "sameAs":[
                "https://github.com/ateliware",
                "https://www.linkedin.com/company/ateliware/",
                "https://twitter.com/ateliware",
                "https://www.youtube.com/channel/UCxPjijyar2emdKLPpOrugOw",
                "https://www.meetup.com/ateliware/",
                "https://goo.gl/maps/gLPc35mF3js7rLnL8"
              ]
            }
          `}
        </script>
      </Helmet>
    </React.Fragment>
  );
};

export default Single;

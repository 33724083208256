import styled, { css } from 'styled-components';
import { StyledWrapper } from 'assets/global/styled';
import colors from 'assets/global/colors';
import fonts from 'assets/global/fonts';

export const StyledIntro = styled.section`
  display: block;
  width: 100%;
  height: 100vh;
  background-color: ${colors.red300};
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  ${(props) =>
    props.bg &&
    css`
      background-image: url('https://media.graphassets.com/resize=w:1200,fit:clip/output=format:webp/compress/${props.bg}');
    `}

  ${StyledWrapper} {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media screen and (max-width: 980px) {
    height: auto;
    padding: 120px 0;
  }

  @media screen and (max-width: 768px) {
    height: auto;
    padding: 60px 0;
  }
`;

export const StyledIntroContainer = styled.div`
  display: block;

  a {
    position: relative;
    display: inline-block;
    margin-top: 20px;
    font-size: 18px;
    color: ${colors.red400};
    text-decoration: none;

    &:before,
    &:after {
      position: absolute;
      bottom: 0;
      width: 0;
      height: 1px;
      background-color: ${colors.red400};
      transition: all 0.3s linear;
      content: '';
    }

    &:before {
      left: 0;
    }

    &:after {
      right: 0;
    }

    &:hover {
      &:before,
      &:after {
        width: 100%;
      }
    }
  }
`;

export const StyledIntroTitle = styled.h1`
  margin-top: 0;
  margin-bottom: 40px;
  font-family: ${fonts.primary};
  font-size: 72px;
  color: ${colors.white};
  line-height: 1.1;

  @media screen and (max-width: 980px) {
    font-size: 60px;
  }

  @media screen and (max-width: 768px) {
    font-size: 42px;
  }

  @media screen and (max-width: 520px) {
    font-size: 28px;
  }
`;

export const StyledIntroDescription = styled.div`
  display: block;
  margin: 30px 0;

  p {
    margin: 5px 0;
    color: ${colors.white};
    font-family: ${fonts.secondary};
  }
`;

import styled from 'styled-components';
import colors from 'assets/global/colors';
import fonts from 'assets/global/fonts';

export const StyledContent = styled.section`
  display: block;
  padding: 80px 0;

  @media screen and (max-width: 980px) {
    padding: 60px 0;
  }

  @media screen and (max-width: 768px) {
    padding: 40px 0;
  }

  a {
    position: relative;
    display: inline-block;
    margin-top: 20px;
    font-size: 16px;
    color: ${colors.red400};
    text-decoration: none;

    &:before,
    &:after {
      position: absolute;
      bottom: 0;
      width: 0;
      height: 1px;
      background-color: ${colors.red400};
      transition: all 0.3s linear;
      content: '';
    }

    &:before {
      left: 0;
    }

    &:after {
      right: 0;
    }

    &:hover {
      &:before,
      &:after {
        width: 100%;
      }
    }
  }
`;

export const StyledTestimonial = styled.div`
  display: block;

  img {
    max-width: 100%;
    height: auto;
  }
`;

export const StyledTestimonialContent = styled.div`
  display: block;
  max-width: 960px;
  margin: -10px auto;
  line-height: 1.8;

  blockquote {
    margin: 10px;
    padding: 0;

    p {
      font-size: 16px;
      font-weight: 300;
      color: ${colors.gray300};
    }
  }

  h6 {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    margin: 10px;
    font-family: ${fonts.primary};
    font-size: 18px;
    color: ${colors.gray300};

    &:before {
      display: block;
      width: 15px;
      height: 2px;
      margin-right: 10px;
      background-color: ${colors.gray300};
      content: '';
    }
  }

  @media screen and (max-width: 960px) {
    blockquote,
    h6 {
      width: 100%;
    }
  }
`;

export const StyledImageFull = styled.figure`
  display: block;
  width: 100%;
  margin-top: 80px;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  text-align: center;

  img {
    max-width: 100%;
    height: auto;
  }
`;

export const StyledStep = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
`;

export const StyledStepContent = styled.div`
  display: block;
  max-width: 45%;

  img {
    max-width: 100%;
    height: auto;
  }

  p {
    font-family: ${fonts.secondary};
    font-size: 18px;
    font-weight: 300;
    color: ${colors.gray300};
    line-height: 1.8;

    @media screen and (max-width: 768px) {
      font-size: 16px;
    }
  }

  @media screen and (max-width: 960px) {
    max-width: 100%;
  }
`;

export const StyledStepContentFull = styled.div`
  display: block;
  max-width: 100%;

  img {
    max-width: 100%;
    height: auto;
  }

  p {
    font-family: ${fonts.secondary};
    font-size: 18px;
    font-weight: 300;
    color: ${colors.gray300};
    line-height: 1.8;

    @media screen and (max-width: 768px) {
      font-size: 16px;
    }
  }
`;

export const StyledStepTitle = styled.h2`
  font-family: ${fonts.primary};
  font-size: 56px;
  color: ${colors.gray300};

  @media screen and (max-width: 960px) {
    font-size: 28px;
  }

  @media screen and (max-width: 460px) {
    font-size: 24px;
  }
`;

export const StyledStepSubtitle = styled.h3`
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: ${fonts.primary};
  font-size: 16px;
  color: ${colors.gray300};

  span {
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 30px;

    &:before {
      display: block;
      width: 100px;
      height: 2px;
      margin-right: 30px;
      background-color: ${colors.gray300};
      content: '';
    }
  }
`;

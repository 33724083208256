import React from 'react';
import FormContact from 'components/FormContact';
import { StyledWrapper, StyledSubtitle } from 'assets/global/styled';
import MarkdownPreview from '@uiw/react-markdown-preview';
import LpDescription from 'components/ContactUs/LpDescription';
import {
  StyledContactUs,
  StyledContactUsContainer,
  StyledContactUsContent,
  StyledContactUsTitle,
  StyledContactUsSubContent,
  StyledLineSeparator
} from './styled';

const ContactUs = (props) => {
  const {
    isContact,
    isLp,
    styledAsLp,
    page,
    contactForm,
    baseURL,
    lang,
    awards,
    ...otherProps
  } = props;

  const isThemeDark = otherProps.dark;
  const bg_image = (page) => {
    if (page !== undefined) {
      return page.content1Image !== null
        ? page.content1Image.url
        : '';
    } else {
      return '';
    }
  };

  return (
    <StyledContactUs
      id="contact"
      isLp={isLp || styledAsLp}
      bg_image={bg_image(page)}
      {...otherProps}
    >
      <StyledWrapper>
        {isThemeDark && (
          <StyledSubtitle dark>
            {page.content1Subtitle}
          </StyledSubtitle>
        )}
        {isThemeDark && (
          <StyledContactUsSubContent>
            {page.content2Subtitle}
          </StyledContactUsSubContent>
        )}
        <StyledContactUsContainer {...otherProps}>
          <StyledContactUsContent {...otherProps}>
            {isContact ? (
              <React.Fragment>
                {!isThemeDark && (
                  <StyledContactUsTitle {...otherProps}>
                    {page.contentTitle}
                  </StyledContactUsTitle>
                )}
                {isThemeDark &&
                  awards?.map((award, index) => {
                    const awardImage = award.image;

                    return (
                      <img
                        src={awardImage?.url}
                        height="100px"
                        width="auto"
                        alt={awardImage?.alt}
                        key={index}
                      />
                    );
                  })}
                {!isThemeDark && (
                  <MarkdownPreview
                    source={page.content}
                    linkTarget={'_blank'}
                  />
                )}
                {isThemeDark && (
                  <React.Fragment>
                    <StyledLineSeparator />
                    <MarkdownPreview
                      source={page.content1}
                      linkTarget={'_blank'}
                    />
                  </React.Fragment>
                )}
              </React.Fragment>
            ) : isLp ? (
              <LpDescription
                page={page}
                baseURL={baseURL}
              ></LpDescription>
            ) : (
              <React.Fragment>
                <StyledContactUsTitle {...otherProps}>
                  {contactForm.title}
                </StyledContactUsTitle>
                <MarkdownPreview source={contactForm.content} />
              </React.Fragment>
            )}
          </StyledContactUsContent>
          <FormContact
            contactForm={contactForm}
            baseURL={baseURL}
            lang={lang}
            isLp={isLp}
            conversionIdentifier={
              isLp ? 'software-company' : 'Envio formulário site'
            }
          />
        </StyledContactUsContainer>
      </StyledWrapper>
    </StyledContactUs>
  );
};

ContactUs.defaultProps = {
  isContact: false,
  isLp: false
};

export default ContactUs;
